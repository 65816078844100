<template>
  <vue-apex-charts
    type="donut"
    height="350"
    :options="apexChatData.donutChart.chartOptions"
    :series="apexChatData.donutChart.series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { ref } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const itemCandidates = ref({
      name: [],
      total: [],
      percentage: [],
    })

    props.candidate.candidate.forEach(itemCandidate => {
      itemCandidates.value.name.push(itemCandidate.name)
      itemCandidates.value.total.push(itemCandidate.total_vote)
      itemCandidates.value.percentage.push(itemCandidate.total_vote_percent)
    })
    const random = Math.floor(Math.random() * itemCandidates.value.name.length)
    const randonName = itemCandidates.value.name[random]
    const randonPercentage = itemCandidates.value.percentage[random]

    const chartColors = {
      column: {
        series1: '#826af9',
        series2: '#d2b0ff',
        bg: '#f8d3ff',
      },
      success: {
        shade_100: '#7eefc7',
        shade_200: '#06774f',
      },
      donut: {
        series1: '#ffe700',
        series2: '#00d4bd',
        series3: '#826bf8',
        series4: '#2b9bf4',
        series5: '#FFA1A1',
      },
      area: {
        series3: '#e0cffe',
        series2: '#b992fe',
        series1: '#ab7efd',
      },
    }
    const apexChatData = {
      donutChart: {
        series: itemCandidates.value.total,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: '"Inter", sans-serif',
          },
          colors: [chartColors.donut.series1, chartColors.donut.series5, chartColors.donut.series3],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: '"Inter", sans-serif',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: '"Inter", sans-serif',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: randonName,
                    formatter() {
                      return `${randonPercentage}%`
                    },
                  },
                },
              },
            },
          },
          labels: itemCandidates.value.name,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }

    return {
      chartColors,
      apexChatData,
    }
  },
}
</script>
